import React from 'react';
import './Footer.css'; 
import { useNavigate } from 'react-router-dom';

function Footer() {

  const navigate= useNavigate(' ')
  const home =()=>{
    
    navigate('/')
     }
     const award =()=>{
        navigate('/Awards')
         }
         const gly =()=>{
            navigate('/Gallery')
             }
             const chi =()=>{
                navigate('/Child')
                 }
                 const can =()=>{
                    navigate('/Cancer')
                     }
                     const env =()=>{
                        navigate('/Env')
                         }
                         const wom =()=>{
                            navigate('/Women')
                             }
                             const rat =()=>{
                                navigate('/Ration')
                                 }
                                 const ani =()=>{
                                    navigate('/Animal')
                                     }
                                     const con =()=>{
                                        navigate('/Contact')
                                         }
                                         const suc =()=>{
                                            navigate('/Success')
                                             }
                                             const don =()=>{
                                                navigate('/Donate')
                                                 }
                                                 const abt =()=>{
                                                    navigate('/about-us')
                                                     }
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
       
          <div className="footer-item">
            <h2 style={{color:'green'}}>About Us</h2>
            <p>
            Indian welfare foundation <br></br> connects citizens, civil society <br></br> groups, corporate houses,<br></br>  academicians, media and<br></br> government  through various<br></br> online and  offline methods. 
            </p>
          </div>

        
          <div className="footer-item">
            <h2 style={{color:'green'}}> Main Links</h2>
            <ul>
             <li onClick={home}>
             Home
             </li>
             <li onClick={abt}>
             About Us
             </li>
             <li onClick={gly}>
             Gallery
             </li>
             <li onClick={award}>
             Awards
             </li>
             <li onClick={suc}>
            Milestone Moments
             </li>
             <li onClick={con}>
             Contact Us
             </li>
            </ul>
          </div>
          <div className="footer-item">
            <h2 style={{color:'green'}}>Quick Links</h2>
            <ul>
             <li onClick={chi}>
             Child Education
             </li>
             <li onClick={can}>
             Cancer Patients Treatment
                </li>
                 <li onClick={env}>
                 Environmental Protection
                </li>
                 <li onClick={wom}>
                 Women Empowerment
                </li> 
                <li onClick={rat}>
                Ration kit to poor Families
                </li>
                <li onClick={ani}>
                Animal Protection
                </li>
            </ul>
          </div>
          <div className="footer-item">
            <h2 style={{color:'green'}}>Contact Us</h2>
            <ul>
              <li>
                <a href='https://www.google.com/maps/place/1%2F257,+Natesan+St,+near+Natesan+Palace,+%5Bno+name%5D,+Vandalur,+Tamil+Nadu+600048/@12.8935473,80.0817075,17z/data=!3m1!4b1!4m6!3m5!1s0x3a52f5e287917dd5:0x111c53428ffc45e6!8m2!3d12.8935473!4d80.0842824!16s%2Fg%2F11rkbdnm8p?entry=ttu'>
                <p>1/260 B ,Natesan street, Vandalur,<br></br> Chennai: 600048</p></a>
              </li>
              <li>
              <a style={{textDecoration:'none',color:'blue'}} href="tel:+9894144922">9894144922</a>,
              <a style={{textDecoration:'none',color:'blue'}} href="tel:+9791173137">9791173137</a>
              </li>
              <li>
              <a style={{textDecoration:'none',color:'blue'}} href="mailto:info@indianwelfarefoundation.org">info@indianwelfarefoundation.org</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

     
      <div className="copyright">
        <p>©<a href="https://mrvitsolutions.com/" style={{textDecoration:'none',border:'none',padding:'0px',margin:'0px'}}>MRV IT SOLUTIONS PVT LTD</a> 2024 . All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;