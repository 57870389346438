import React, { useEffect, useRef } from 'react'
import './chi.css'
export default function Child() {
    const aboutUsRef = useRef(null);
   
    useEffect(() => {
        
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <div className="image-section-chi first-section-chi">
                <div class="carousel-overlay-img"></div>
                <div className="animate one">
        <span>C</span><span>H</span><span>I</span><span>L</span><span>D</span> &nbsp;
        <span>E</span><span>D</span><span>U</span><span>C</span><span>A</span><span>T</span><span>I</span><span>O</span><span>N</span>
      </div>                 </div>
            <div ref={aboutUsRef} className="content-container">

                <div className="left-content" style={{ 
                    backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnpBzVQO2E9nPAMqgSnXZIbP74uTfIdU1Ekg&s)', 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat', 
                    opacity: 0.8, 
                    padding: '20px', 
                    color: 'black' ,
                    borderRadius:'20px'
                }}>
                <p><h1 style={{fontWeight:'bold', fontSize: 35,fontFamily: 'cursive',}}>Indian Welfare Foundation – Child Education Initiative</h1></p>

                    <p>The commitment to gender equality is at the core of our mission at the Indian Welfare Foundation. Through our unwavering dedication, we ensure that girl child education receives equal attention and resources. By fostering an inclusive educational environment, we strive to empower every child, irrespective of gender, providing them with the tools to shape their own destinies. Together, we work towards a future where every girl has the opportunity to learn, grow, and contribute meaningfully to society.</p>

                </div>

                <div className="right-content-chi   .chi" >

                    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOD4fXHPEi25DSL5xjgn_ehEZkfwAKOQowAg&s' alt="child education image" />
                </div>
            </div>
            <div ref={aboutUsRef} className="content-container">

                <div className="right-content-chi -ch" >
                    <img src='https://miro.medium.com/v2/resize:fit:1000/1*QVtgYvHLy90a8-YlowTmmw.jpeg' alt="child education image" />

                </div>

                <div className="right-content" style={{ 
                    backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbx7m6r9zDijMORy0Ugo2euL8JT8L4CRXwPw&s)', 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat', 
                    opacity: 0.8, 
                    padding: '20px', 
                    color: 'black' ,
                    borderRadius:'20px'
                }}>
<p>
<b>Introduction:</b>
The Indian Welfare Foundation (IWF) is fervently committed to transforming lives through its dedicated Child Education Initiative. Focused on breaking the chains of illiteracy and empowering the future generations, this program stands as a testament to our unwavering dedication to educational upliftment.
<br></br><br></br>
<b>Objectives:</b><br></br>
<b>1. Universal Access:</b> Ensuring every child, regardless of socio-economic background, has access to quality education.<br></br>
<b>2. Holistic Development:</b> Nurturing not just academic skills but also fostering creativity, critical thinking, and character development.<br></br>
<b>3. Community Involvement:</b> Collaborating with local communities to create a supportive educational ecosystem.<br></br>
<br></br><p><strong>Program Components:</strong><br></br><strong>1. School Infrastructure Improvement:</strong> Upgrading facilities, providing essential learning resources, and creating an engaging environment for effective learning.<br></br><strong>2. Scholarship Programs:</strong> Facilitating access to education for underprivileged children through need-based scholarships.<br></br><strong>3. Teacher Training:</strong> Enhancing the quality of education by providing continuous training and professional development opportunities for educators.</p>
                    </p>
                </div>
            </div>
            <div ref={aboutUsRef} className="content-container">

<div className="left-content" style={{ 
                    backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ39trFRDc5AkS2zuqeIhlmFuV-ceUyKL4aEA&s)', 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    backgroundRepeat: 'no-repeat', 
                    opacity: 0.8, 
                    padding: '20px', 
                    color: 'black' ,
                    borderRadius:'20px'
                }}>

    <p><p><strong>Impact:</strong><br></br><strong>1. Increased Enrollment:</strong> A surge in school enrollment rates, particularly among marginalized communities.<br></br><strong>2. Academic Excellence:</strong> Demonstrable improvement in academic performance and learning outcomes.<br></br><strong>3. Community Empowerment:</strong> Fostering a sense of community pride and involvement in the educational journey of their children.</p></p>
    <p><strong>Challenges and Solutions:</strong><br></br><strong>1. Infrastructure Gaps:</strong> Addressed through strategic partnerships, fundraising, and community involvement in construction and maintenance projects.<br></br><strong>2. Financial Barriers:</strong> Mitigated through targeted scholarship programs, sponsorship initiatives, and advocacy for accessible education policies.</p>
    <p><strong>Future Roadmap:</strong><br></br><strong>1. Scale and Reach:</strong> Expanding our footprint to reach more underserved regions across India.<br></br><strong>2. Technology Integration:</strong> Embracing innovative tools and digital resources to enhance the learning experience.<br></br><strong>3. Collaborative Initiatives:</strong> Strengthening partnerships with government bodies, NGOs, and corporate entities to amplify our impact.</p>

</div>

<div className="right-content-chi image-container1">

    <img src='https://www.eschoolnews.com/files/2023/07/arts-education-STEAM.jpeg' alt="child education image" />
</div>
</div>
<div className='chi'>
  <div className="overlay5"></div>
  <div className="text"><p><strong>Conclusion:</strong><br></br>The Indian Welfare Foundation’s Child Education Initiative stands as a beacon of hope, lighting the path towards a future where every child has the opportunity to realize their full potential through education. As we forge ahead, guided by our mission, we invite continued support and collaboration in our collective journey towards a more educated and empowered society.</p>
<p><strong>The Importance of Girl Child Education</strong></p>
<p>Empowering societies through the education of girls is not just a moral imperative; it’s a catalyst for positive change. Here’s why girl child education holds paramount significance:</p>
<p><strong>1. Breaks the Cycle of Poverty:</strong> Education equips girls with the knowledge and skills to escape the cycle of poverty, leading to improved economic outcomes for themselves and their families.</p>
<p><strong>2. Enhances Social Development:</strong> Educated girls contribute to the social fabric by becoming active, informed, and engaged citizens, fostering community development and cohesion.</p>
<p><strong>3. Reduces Child Mortality:</strong> Educated mothers are more likely to prioritize healthcare, resulting in lower child mortality rates and better overall family health.</p>
<p><strong>4. Empowers Through Knowledge:</strong> Education provides girls with the tools to make informed decisions about their lives, health, and future, empowering them to break down societal barriers.</p>
<p><strong>5. Contributes to Economic Growth:</strong> The education of girls is linked to higher economic productivity, contributing to the overall development and growth of a nation.</p>
<p><strong>6. Promotes Gender Equality:</strong> Education is a key driver in challenging and dismantling gender stereotypes, fostering a more equitable society.</p>
<p><strong>7. Addresses Population Growth:</strong> Educated girls tend to have fewer children, contributing to sustainable population growth and family planning.</p>
<p><strong>8. Strengthens National Security:</strong> Education is a powerful deterrent to extremism and conflict, as it promotes critical thinking, tolerance, and a sense of global citizenship.</p>
<p><strong>9. Catalyzes Innovation:</strong> Girls’ education fuels innovation by tapping into a diverse pool of talent and perspectives, driving progress in science, technology, and other fields.</p>
<p><strong>10. Global Progress:</strong> Achieving global development goals, such as the Sustainable Development Goals (SDGs), is intrinsically linked to ensuring that every girl has access to quality education.</p>
<p>In essence, investing in the education of girls is an investment in the prosperity and well-being of communities, nations, and the world at large. The Indian Welfare Foundation is dedicated to advancing this cause, recognizing the transformative impact of girl child education on the fabric of society. Join us in creating a future where every girl can fulfill her potential through the power of education.</p>
</div></div>
        </div>
    )
}
