import React, { useState, useEffect } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';

const images = [
  { src: 'https://media.gettyimages.com/id/535555239/photo/happy-indian-school-children.jpg?s=612x612&w=0&k=20&c=fcpTUHiHJuaeRS-xHJy4oOflwKpBooiPecyewzohvhk=', alt: 'Image 1 name' },
  { src: 'https://media.gettyimages.com/id/484168285/photo/mother-and-child-india.jpg?s=612x612&w=0&k=20&c=jW6EYwQGBES5tYPjwtHDNit8ybtihqt525CsDVIgJIw=', alt: 'Image 2 name' },
  { src: 'https://media.gettyimages.com/id/535555239/photo/happy-indian-school-children.jpg?s=612x612&w=0&k=20&c=fcpTUHiHJuaeRS-xHJy4oOflwKpBooiPecyewzohvhk=', alt: 'Image 3 name' },
  { src: 'https://media.gettyimages.com/id/629691872/photo/indian-little-girl-drinking-fresh-water-desert-village-rajasthan-india.jpg?s=612x612&w=0&k=20&c=Fap2xlrRnnt_KU9Sb6dljbvMqOnVeeVbDeLOI43ejwg=', alt: 'Image 4 name' },
  { src: 'https://media.gettyimages.com/id/535555239/photo/happy-indian-school-children.jpg?s=612x612&w=0&k=20&c=fcpTUHiHJuaeRS-xHJy4oOflwKpBooiPecyewzohvhk=', alt: 'Image 5 name' },
  { src: 'https://media.gettyimages.com/id/484168285/photo/mother-and-child-india.jpg?s=612x612&w=0&k=20&c=jW6EYwQGBES5tYPjwtHDNit8ybtihqt525CsDVIgJIw=', alt: 'Image 6 name' },
];

const captions = [
  ' lorem fasdfsdf adfasdf adfsdf dfsadf adfFlorem fasdfsdf adfasdf adfsdf dfsadf adfFlorem fasdfsdf adfasdf adfsdf dfsadf adfFlorem fasdfsdf adfasdf adfsdf dfsadf adfF',
  'Caption 2',
  'lorem fasdfsdf adfasdf adfsdf dfsadf adfF lorem fasdfsdf adfasdf adfsdf dfsadf adfFlorem fasdfsdf adfasdf adfsdf dfsadf adfFlorem fasdfsdf adfasdf adfsdf dfsadf adfF',
  'Caption 4',
  'Caption 5',
  'Caption 6',
];

function Slideshow() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slideIn, setSlideIn] = useState(true);
    const [paused, setPaused] = useState(false);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (!paused) {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
      }, 7000); 
      return () => clearInterval(intervalId);
    }, [currentIndex, paused]);
  
    const handleMouseEnter = () => {
      setPaused(true); 
    };
  
    const handleMouseLeave = () => {
      setPaused(false); 
    };
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setSlideIn(false);
        setTimeout(() => {
          const nextIndex = (currentIndex + 1) % images.length;
          setCurrentIndex(nextIndex);
          setSlideIn(true);
        }, 500);
      }, 7000); 
      return () => clearInterval(intervalId);
    }, [currentIndex]);
  const navigate= useNavigate('')
  const don =()=>{
    navigate('/Donate')
     }
    return (
      <div className="slideshow-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={`slide ${slideIn ? "slide-in" : "slide-out"}`}>
          <div className="image-container1">
            <img src={images[currentIndex].src} alt={images[currentIndex].alt} />
          </div>
        </div>
        <div className="caption">
          <p>{captions[currentIndex]}</p>
          <button className="deposit-button" style={{fontFamily: 'cursive'}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={don}>DONATE</button>
          <p>Additional content here...</p>
        </div>
        <a className="prev" onClick={() => setCurrentIndex((currentIndex - 1 + images.length) % images.length)}>&#10094;</a>
        <a className="next" onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}>&#10095;</a>
      </div>
    );
  }
  
  export default Slideshow;