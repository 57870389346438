import logo from './logo.svg';
import './App.css';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Navbar from './Navbar';
import Slideshow from './Slideshow';
import Dashboard from './Dashboard';
import Footer from './Footer';
import Volunteer from './Volunteer';
import StickyIcon from './StickyIcon';
import Gallery from './Gallery';
import Child from './Child';
import Cancer from './Cancer';
import Env from './Env';
import Women from './Women';
import Ration from './Ration';
import Animal from './Animal';
import Contact from './Contact';
import Success from './Success';
import Donate from './Donate';
import About from './About';
import Payment from './Payment';
function App() {
  return (
   <div>
   

<BrowserRouter>
<Navbar/>
<Routes>
  <Route path='/' element={<Dashboard/>}></Route>
  <Route path='/Slideshow' element={<Slideshow/>}></Route>
  <Route path='/Volunteer' element={<Volunteer/>}></Route>
  <Route path='/Navbar' element={<Navbar/>}></Route>
  <Route path='/Gallery' element={<Gallery/>}></Route>
  <Route path='/child' element={<Child/>}></Route>
  <Route path='/Cancer' element={<Cancer/>}></Route>
  <Route path='/Env' element={<Env/>}></Route>
  <Route path='/Women' element={<Women/>}></Route>
  <Route path='/Ration' element={<Ration/>}></Route>
  <Route path='/Animal' element={<Animal/>}></Route>
  <Route path='/Contact-us' element={<Contact/>}></Route>
  <Route path='/Success' element={<Success/>}></Route>
  <Route path='/Donate' element={<Donate/>}></Route>
  <Route path='/about-us' element={<About/>}></Route>
  <Route path='/Payment' element={<Payment/>}></Route>

</Routes>
<Footer/>
</BrowserRouter>


   </div>
  );
}

export default App;
