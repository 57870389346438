import React, { useEffect, useState, useRef } from 'react';
import './Donate.css';

export default function Donate() {
  const aboutUsRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePayment = (e) => {
    e.preventDefault();

    const options = {
      key: 'rzp_live_tAzX0NAP9SBY6Q', // Replace with your actual Razorpay key
      amount: amount * 100, // Amount in paisa (e.g., ₹500 = 50000 paisa)
      currency: 'INR',
      name: 'Indian Welfare Foundation',
      description: 'Test Transaction',
      image: 'https://example.com/your_logo',
      handler: function (response) {
        alert(`Payment ID: ${response.razorpay_payment_id}`);
        alert(`Order ID: ${response.razorpay_order_id}`);
        alert(`Signature: ${response.razorpay_signature}`);
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(`Error Code: ${response.error.code}`);
      alert(`Description: ${response.error.description}`);
      alert(`Source: ${response.error.source}`);
      alert(`Step: ${response.error.step}`);
      alert(`Reason: ${response.error.reason}`);
      alert(`Order ID: ${response.error.metadata.order_id}`);
      alert(`Payment ID: ${response.error.metadata.payment_id}`);
    });

    rzp1.open();
  };

  return (
    <div>
      <div className="image-section-don first-section-don">
        <div className="carousel-overlay-don"></div>
        <div className="animate one">
          <span>D</span><span>O</span><span>N</span><span>A</span><span>T</span><span>E</span> &nbsp;
          <span>U</span><span>S</span>
        </div>
      </div>
      <div className="donate-container">
        <iframe
          src="https://pages.razorpay.com/pl_ORpww6HPGxjeIn/view"
          frameBorder="0"
          width="100%"
          height="600px"
          title="Razorpay Donation Page"
        ></iframe>
      </div>
      <div className="chi">
        <div className="overlay5"></div>
        <div className="text">
          <p>I am reaching out to you with great enthusiasm and a sense of purpose on behalf of our NGO. At the heart of our organization lies a vision to create a positive impact in the lives of those in need, driven by a commitment to education and healthcare.</p>
          <p><strong>Our Mission:</strong><br />Our primary focus is on child education, aiming to empower the younger generation with the knowledge and skills necessary for a brighter future. We firmly believe that education is the cornerstone of positive societal change.</p>
          <p><strong>Cancer Treatment Program:</strong><br />In addition to our dedication to education, we have embarked on a crucial initiative – a cancer treatment program. Recognizing the urgent need for comprehensive support in this area, we aim to provide assistance, care, and resources to individuals battling cancer.</p>
          <p><strong>How Your Support Makes a Difference:</strong><br />Your generous contributions play a pivotal role in turning our vision into reality. With your support, we can enhance the educational opportunities for underprivileged children and offer a lifeline to those undergoing cancer treatment.</p>
          <p><strong>Transparency and Accountability:</strong><br />We are committed to utmost transparency in our operations. Regular updates and reports on the impact of your donations will be shared, ensuring you are well-informed about the positive changes your support is bringing about.</p>
          <p><strong>Join Us in Creating Change:</strong><br />I invite you to join hands with us in making a meaningful difference. Your support is not just a donation; it’s an investment in a brighter, healthier, and more educated future for those in need.</p>
          <p><strong>Thank you for considering our cause. Together, let’s build a better world.</strong></p>
          <p>Best regards,</p>
          <p>Founder : Nageshwaren Kothandapani , <br />Indian Welfare Foundation</p>
        </div>
      </div>
    </div>
  );
}
