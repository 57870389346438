import React,{useEffect,useRef,useState} from 'react'
import './Volunteer.css'
import emailjs from 'emailjs-com';
export default function Volunteer() {
    const aboutUsRef = useRef(null);
   
    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, []);
      const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        profession: '',
        preferredDay: '',
        preferredTiming: '',
        
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const [load,setLoad]= useState(false)
      const [show,setShow]= useState(false)

      const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true)

        console.log(formData)
        emailjs.send('service_qucoi14', 'template_vcrqn4p',{firstName:formData.firstName,email:formData.email,phone:formData.phone,country:formData.country,
        preferredDay:formData.preferredDay,preferredTiming:formData.preferredTiming,profession:formData.profession
        
        }, 'v7Slyc_tsd75zB52s')
          .then((result) => {
              console.log(result.text);
              setLoad(false);
              setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                country: '',
                profession: '',
                preferredDay: '',
                preferredTiming: '',
            });
            setShow(true);
            setTimeout(() => setShow(false), 5000);

          }, (error) => {
              console.log(error.text);
              setLoad(false)

          });
      };
  return (
    <div>
      <span>
       {show && (
                <div className="thought">
                    Request Submitted Successfully
                    <div className="progress-line"></div>
                </div>
            )}
        </span>
         <div>
      
      <div className="image-section first-section">
      <div class="carousel-overlay-img"></div>
      <div className="animate one">
        <span>V</span><span>O</span><span>L</span><span>U</span><span>N</span><span>T</span><span>E</span><span>E</span><span>R</span> &nbsp;
       
      </div>           </div>

     
      <div className="image-section1 second-section">
      <form className='form1' onSubmit={handleSubmit}>
      <div className="form-container">
        <div>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            className="form-input1"
            required
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="form-input1"
            required
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-input1"
            required
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            className="form-input1"
            required
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="text"
            name="country"
            placeholder="Current Country"
            className="form-input1"
            required
            value={formData.country}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="text"
            name="profession"
            placeholder="Enter your Profession"
            className="form-input1"
            required
            value={formData.profession}
            onChange={handleChange}
          />
        </div>
        <div>
          <label style={{color:'black',fontSize:'18px'}}>Preferred Date:</label>
          <input
            type="date"
            name="preferredDay"
            placeholder="Preferred day"
            className="form-input1"
            required
            value={formData.preferredDay}
            onChange={handleChange}
          />
        </div>
        <div>
          <label style={{color:'black',fontSize:'18px'}}>Preferred Timing:</label>
          <input
            type="time"
            name="preferredTiming"
            placeholder="Preferred Timing"
            className="form-input1"
            required
            value={formData.preferredTiming}
            onChange={handleChange}
          />
        </div>
      </div>
      <center>
      <button type="submit" style={{ width: '30%', padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
                  {  load ? (
           <center>  <div className="loading-spinner"></div></center>   
                  ):(
                   <h6>Register</h6>
                  )}
                </button>      </center>
    </form>
       
      </div>
      
    </div>
    </div>
  )
}
