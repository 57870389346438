import React, { useState, useEffect, useRef } from 'react';
import './imgstyle.css';
import { useNavigate } from 'react-router-dom';

const ConsoleText = ({ words, id, colors }) => {
  const [letterCount, setLetterCount] = useState(1);
  const [x, setX] = useState(1);
  const [waiting, setWaiting] = useState(false);
  const [visible, setVisible] = useState(true);
  const [currentWords, setCurrentWords] = useState(words);
  const [currentColors, setCurrentColors] = useState(colors);

  useEffect(() => {
    if (currentColors.length === 0) {
      setCurrentColors(['#fff']);
    }

    const intervalId = setInterval(() => {
      if (letterCount === 0 && !waiting) {
        setWaiting(true);
        setTimeout(() => {
          const usedColor = currentColors.shift();
          currentColors.push(usedColor);
          const usedWord = currentWords.shift();
          currentWords.push(usedWord);
          setX(1);
          document.getElementById(id).setAttribute('style', 'color:' + currentColors[0]);
          setLetterCount(1);
          setWaiting(false);
        }, 1000);
      } else if (letterCount === currentWords[0].length + 1 && !waiting) {
        setWaiting(true);
        setTimeout(() => {
          setX(-1);
          setLetterCount(letterCount - 1);
          setWaiting(false);
        }, 1000);
      } else if (!waiting) {
        document.getElementById(id).innerHTML = currentWords[0].substring(0, letterCount);
        setLetterCount(letterCount + x);
      }
    }, 120);

    return () => clearInterval(intervalId);
  }, [letterCount, waiting, currentWords, currentColors, x, id]);

  useEffect(() => {
    const underscoreIntervalId = setInterval(() => {
      setVisible((prevVisible) => !prevVisible);
    }, 400);

    return () => clearInterval(underscoreIntervalId);
  }, []);

  return (
    <div className="console-container">
      <span id={id}></span>
      <div className="console-underscore" id="console" style={{ opacity: visible ? 1 : 0 }}>
        &#95;
      </div>
    </div>
  );
};
export default function Images() {
  const navigate = useNavigate();
  const placeholderRef = useRef(null);
 
  const don = () => {
    navigate('/Donate');
  };

  return (
    <div>
      <div className="container">
        <img src="https://media.gettyimages.com/id/524903696/photo/poor-indian-children-asking-for-food-india.jpg?s=612x612&w=0&k=20&c=uAUDyZRdpReAW51hD29W7fOCTDzNrOdHkAdKXErbapU=" alt="Background image" className="background-image" />
        <div className="content">
        <ConsoleText
      words={['Delivering help and hope to children through sponsorship.', 'Helping hands for those in need.', 'Bringing smiles to faces.','Together, we can change the world.']}
      id="text"
      colors={['white', 'white', 'white','white']}
    />
          <button style={{width:'200px',height:'50px',display:'block'}} className="donate-button donate" onClick={don}>Donate Now&#x1F496;</button>
        </div>
      </div>
    </div>
  );
}
