import React,{useEffect,useRef} from 'react'
import './env.css'

export default function Env() {
    const aboutUsRef = useRef(null);
   
    useEffect(() => {
       
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>  
          <div className="image-section-env first-section-env">  
                <div class="carousel-overlay-img"></div>
                <div className="animate one">
        <span>E</span><span>N</span><span>V</span><span>I</span><span>R</span><span>O</span><span>N</span><span>M</span><span>E</span><span>N</span><span>T</span><span>A</span><span>L</span> &nbsp;
        <span>P</span><span>R</span><span>O</span><span>T</span><span>E</span><span>C</span><span>T</span><span>I</span><span>O</span><span>N</span>
      </div>                 </div>
     
            <div className='env'>
  <div className="overlay5"></div>
  <div className="text">
  <p><strong>Afforestation and Reforestation Benefits:</strong></p>
  <p><strong>1. Carbon Sequestration:</strong> Trees absorb carbon dioxide during photosynthesis and store carbon in their biomass and soils. Afforestation (planting trees in areas that were not forested before) and reforestation (replanting trees in deforested or degraded areas) act as significant carbon sinks, helping mitigate climate change.</p>
  <p><strong>2. Biodiversity Conservation:</strong> Forests provide habitats for a diverse range of plant and animal species. Afforestation and reforestation efforts contribute to the restoration of ecosystems, supporting biodiversity and protecting endangered species.</p>
  <p><strong>3. Erosion Control:</strong> Tree roots help stabilize soil, preventing erosion. Afforestation and reforestation projects can protect against landslides and soil degradation, especially in areas prone to erosion.</p>
  <p><strong>4. Improved Water Quality:</strong> Trees act as natural filters, trapping sediments and pollutants. Forested areas help maintain water quality by reducing runoff and filtering pollutants before they reach water bodies.</p>
  <p><strong>5. Water Cycle Regulation:</strong> Trees play a crucial role in regulating the water cycle. They absorb and release water through transpiration, helping to maintain consistent rainfall patterns and reduce the risk of floods and droughts.</p>
  <p><strong>6. Microclimate Regulation:</strong> Forests influence local climate conditions by providing shade and regulating temperatures. Afforestation and reforestation contribute to the creation of cooler microclimates, especially in urban areas.</p>
  <p><strong>7. Economic Opportunities:</strong> Forests, resulting from afforestation and reforestation, can provide sustainable economic opportunities. This includes timber and non-timber forest products, ecotourism, and job opportunities in forest management.</p>
  <p><strong>8. Air Quality Improvement:</strong> Trees help improve air quality by absorbing pollutants and releasing oxygen. Afforestation and reforestation projects can contribute to reducing air pollution, particularly in urban environments.</p>
  <p><strong>9. Enhanced Aesthetic Value:</strong> Forested landscapes enhance the aesthetic value of an area, making it more visually appealing. This can contribute to increased well-being and quality of life for nearby communities.</p>
  <p><strong>10. Climate Resilience:</strong> Afforestation and reforestation contribute to climate resilience by creating buffer zones against extreme weather events. Forests act as natural barriers, protecting communities from floods, hurricanes, and other natural disasters.</p>
  <p><strong>11. Community Engagement:</strong> Involving local communities in afforestation and reforestation projects can foster a sense of ownership and stewardship. This engagement is essential for the long-term success and sustainability of such initiatives.</p>
  <p><strong>12. Sustainable Resource Management:</strong> Afforestation and reforestation projects promote sustainable land use and resource management. This involves balancing ecological conservation with human needs, ensuring the long-term health of the ecosystem.</p>
  <p>In summary, afforestation and reforestation play crucial roles in mitigating climate change, conserving biodiversity, improving water quality, and providing various social, economic, and environmental benefits. These efforts contribute to a more sustainable and resilient future.</p>

    </div></div>
    </div>
  )
}
