import React, { useState, useEffect } from 'react';

const RazorpayIntegration = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePayment = (e) => {
    e.preventDefault();

    const options = {
      key: 'rzp_live_tAzX0NAP9SBY6Q', // Replace with your actual Razorpay key
      amount: amount * 100, // Amount in paisa (e.g., ₹500 = 50000 paisa)
      currency: 'INR',
      name: 'indian welfare foundation',
      description: 'Test Transaction',
      image: 'https://example.com/your_logo',
      handler: function (response) {
        alert(`Payment ID: ${response.razorpay_payment_id}`);
        alert(`Order ID: ${response.razorpay_order_id}`);
        alert(`Signature: ${response.razorpay_signature}`);
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(`Error Code: ${response.error.code}`);
      alert(`Description: ${response.error.description}`);
      alert(`Source: ${response.error.source}`);
      alert(`Step: ${response.error.step}`);
      alert(`Reason: ${response.error.reason}`);
      alert(`Order ID: ${response.error.metadata.order_id}`);
      alert(`Payment ID: ${response.error.metadata.payment_id}`);
    });

    rzp1.open();
  };

  return (
    <div>
     
     <div className="razorpay-container">
      <div className="razorpay-left-section">
        <img src="https://example.com/your_logo" alt="Logo" className="razorpay-logo" />
      </div>
      <div className="razorpay-right-section">
        <h2>Razorpay Payment Integration</h2>
        <form onSubmit={handlePayment}>
          <div className="razorpay-form-group">
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
          </div>
          <div className="razorpay-form-group">
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
          </div>
          <div className="razorpay-form-group">
            <label>
              Phone:
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </label>
          </div>
          <div className="razorpay-form-group">
            <label>
              Amount:
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </label>
          </div>
          <button type="submit" id="rzp-button1">
            Pay Now ({amount})
          </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default RazorpayIntegration;
