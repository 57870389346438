import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import './test.css';

const CounterCard = ({ value, label }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      let start = 0;
      const end = parseInt(value, 10);
      if (start === end) return;

      const totalDuration = 2000;
      const incrementTime = Math.abs(Math.floor(totalDuration / end));
      
      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === end) clearInterval(timer);
      }, incrementTime);
    }
  }, [inView, value]);

  return (
    <div ref={ref} className={`card1 ${inView ? 'fade-in' : ''}`}>
      <div className="card-value">{count}+</div>
      <div className="card-label">{label}</div>
    </div>
  );
};

export default CounterCard;
