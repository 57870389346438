import React, { useEffect, useRef } from 'react';
import './success.css';

export default function Success() {
  const aboutUsRef = useRef(null);

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="image-section-suc first-section-suc">
        <div className="carousel-overlay-img"></div>
        <div className="animate one" style={{textTransform:'uppercase'}}>
        <span>M</span><span>i</span><span>l</span><span>e</span><span>s</span><span>t</span><span>o</span><span>n</span><span>e</span>&nbsp;
    <span>M</span><span>o</span><span>m</span><span>e</span><span>n</span><span>t</span><span>s</span>    </div>     
      </div>
      <div className="grid-container-suc">
        {/* First Row */}
        <div className="grid-item-suc">
          <video
            src="https://indianwelfarefoundation2.s3.ap-south-1.amazonaws.com/3v.mp4" alt="video"
            controls
          ></video>
        </div>
        <div className="grid-item-suc">
          <video
            src="https://indianwelfarefoundation2.s3.ap-south-1.amazonaws.com/6v.mp4" alt="video"
            controls
          ></video>
        </div>
        <div className="grid-item-suc">
          <video
            src="https://indianwelfarefoundation2.s3.ap-south-1.amazonaws.com/7v.mp4" alt="video"
            controls
          ></video>
        </div>
      
        <div className="grid-item-suc">
          <video
            src="https://indianwelfarefoundation2.s3.ap-south-1.amazonaws.com/64v.mp4" alt="video"
            controls
          ></video>
        </div>
      </div>
    </div>
  );
}
